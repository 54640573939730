// src/store/teslaAccountsSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InlineVehicle {
    id: number;
    name: string;
}

export interface TeslaAccount {
    id: number;
    email: string;
    vehicles: InlineVehicle[];
    errors: TeslaError[];
    created_at: number;
    updated_at: number;
}

export enum TeslaErrorType {
    other = 'other',
    scope = 'scope_error',
    auth = 'auth_error'
}

export interface TeslaError {
    source: string;
    type: TeslaErrorType;
    message: string;
}

export interface TeslaAccountsState {
    accounts: TeslaAccount[];
    loading: boolean;
    error: string | null;
}

const initialState: TeslaAccountsState = {
    accounts: [],
    loading: false,
    error: null,
};

const teslaAccountsSlice = createSlice({
    name: 'teslaAccounts',
    initialState,
    reducers: {
        setAccounts: (state, action: PayloadAction<TeslaAccount[]>) => {
            state.accounts = action.payload;
        },
        addAccount: (state, action: PayloadAction<TeslaAccount>) => {
            state.accounts.push(action.payload);
        },
        updateAccount: (state, action: PayloadAction<TeslaAccount>) => {
            const index = state.accounts.findIndex(account => account.id === action.payload.id);
            if (index !== -1) {
                state.accounts[index] = action.payload;
            }
        },
        removeAccount: (state, action: PayloadAction<number>) => {
            state.accounts = state.accounts.filter(account => account.id !== action.payload);
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    setAccounts,
    addAccount,
    updateAccount,
    removeAccount,
    setLoading,
    setError,
} = teslaAccountsSlice.actions;

export default teslaAccountsSlice.reducer;